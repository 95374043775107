window.showPopup = function(){
 copyDiscord();
  document.getElementById("discord-toast").classList.remove("show");
 setTimeout(()=>document.getElementById("discord-toast").classList.toggle("show"),50);
}
window.copyDiscord = function(){
  navigator.clipboard.writeText('its.wwc').then(() => {
    const elm = document.getElementById('discordTooltip');
    elm.style.display = 'none';
    const newone = elm.cloneNode(true);
    elm.parentNode.replaceChild(newone, elm);
    newone.style.display = 'block';
  });
}
